import React from 'react';
import { Container, Card} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Page from 'components/page';
import { Consts } from "consts";

const Terms = () => {
  const [t] = useTranslation('common');

  return (
        <Page title={t('Terms of Service')} description={t('Terms of Service')}>
        <Container className="min-vh-100 my-5">
            <Card>
                <Card.Body>
                <h1 className="mb-4">Terms of Service</h1>
                <p><em>Last Updated: October 31, 2024</em></p>
                <p>
                    Welcome to {Consts.ProjectName} ("we," "us," or "our"). By accessing or using our grammar checking web service ("Service"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our Service.
                </p>
                <hr />

                <h3>1. Acceptance of Terms</h3>
                <p>
                    By accessing or using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and any additional guidelines.
                </p>

                <h3>2. Description of Service</h3>
                <p>
                    Our Service provides users with tools to check text content for grammar errors. The Service analyzes the text on websites provided by users and provides suggestions for corrections.
                </p>

                <h3>3. User Responsibilities</h3>
                <ul>
                    <li>
                    <strong>Accuracy of Information:</strong> You are responsible for the accuracy, quality, and legality of the text content you submit.
                    </li>
                    <li>
                    <strong>Compliance:</strong> You agree to use the Service in compliance with all applicable laws and regulations.
                    </li>
                    <li>
                    <strong>Account Security:</strong> If you create an account, you are responsible for maintaining the confidentiality of your login information.
                    </li>
                </ul>

                <h3>4. Intellectual Property Rights</h3>
                <ul>
                    <li>
                    <strong>Our Rights:</strong> All content, features, and functionality of the Service are the exclusive property of {Consts.ProjectName} and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
                    </li>
                    <li>
                    <strong>Your Rights:</strong> You retain all rights to the text content you submit. We claim no ownership over your submitted content.
                    </li>
                </ul>

                <h3>5. Privacy Policy</h3>
                <p>
                    Our Privacy Policy outlines how we collect, use, and protect your personal information. By using the Service, you consent to the collection and use of information as described in the Privacy Policy.
                </p>

                <h3>6. Disclaimer of Warranties</h3>
                <p>
                    The Service is provided on an "AS IS" and "AS AVAILABLE" basis. We make no warranties, expressed or implied, regarding the reliability, accuracy, or completeness of the Service.
                </p>

                <h3>7. Limitation of Liability</h3>
                <p>
                    In no event shall {Consts.ProjectName}, its directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service.
                </p>
                <p>
                    <strong>Disclaimer:</strong> We are not liable for any damages or losses resulting from errors, mistakes, or inaccuracies in the text content checked by our Service. You acknowledge that any reliance on the Service is at your own risk.
                </p>

                <h3>8. Indemnification</h3>
                <p>
                    You agree to defend, indemnify, and hold harmless {Consts.ProjectName} and its affiliates from any claims, damages, obligations, losses, liabilities, costs, or debt arising from:
                </p>
                <ul>
                    <li>Your use of and access to the Service.</li>
                    <li>Your violation of any term of these Terms.</li>
                    <li>Your violation of any third-party rights.</li>
                </ul>

                <h3>9. Modifications to the Service and Terms</h3>
                <p>
                    We reserve the right to modify or discontinue the Service at any time without notice. We may also update these Terms from time to time. Continued use of the Service after any such changes shall constitute your consent to such changes.
                </p>

                <h3>10. Governing Law</h3>
                <p>
                    These Terms shall be governed and construed in accordance with the laws of the State of Wyoming, United States, without regard to its conflict of law provisions.
                </p>

                <h3>11. Severability</h3>
                <p>
                    If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in effect.
                </p>

                <h3>12. Entire Agreement</h3>
                <p>
                    These Terms constitute the entire agreement between us regarding our Service and supersede any prior agreements.
                </p>

                <h3>13. Contact Information</h3>
                <p>
                    If you have any questions about these Terms, please contact us at:
                </p>
                <ul>
                    <li><strong>Email:</strong> {Consts.ContactEmail}</li>
                </ul>

                <hr />
                <p>
                    By using our Service, you acknowledge that you have read and agree to these Terms of Service.
                </p>
                </Card.Body>
            </Card>
        </Container>
        </Page>
  );
};

export default Terms;