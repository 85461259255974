import React from "react";
import {Navbar, Nav, Image, Button} from 'react-bootstrap';
import "styles/theme.css";
import Logo from "assets/logo3.svg"
import { NavLink } from 'react-router-dom';
import { Paths } from "paths";
import { useEffect, useState } from 'react';
import getBackend from "backend/backend";
import { useTranslation } from "react-i18next";
import { googleLogout } from '@react-oauth/google';
import { Consts } from "consts";
import { useLocation } from "react-router-dom";

const Header = (props) => {
  const { t } = useTranslation('common');

  const [profile, setProfile] = useState({});
  const location = useLocation();

  useEffect(() => {
      let canceled = false;

      const getProfile = async () => {
          const result = await getBackend().profile();
          if (canceled)
            return;

          if (result.error == null) {
              setProfile(result.response);
          }
      };

      getProfile();

      return async () => {
          canceled = true;
      }
  }, []);

  const handleSignOut = async () => {
    const result = await getBackend().signout();
    if (result.error) {
        window.location.href = Paths.ServerError.path;
    } else {
        if (result.response.auth_type === "google") {
            googleLogout();
        }
        window.location.href = Paths.Home.path;
    }
  };

  return (
    <>
        <header className="header mt-auto py-3">
        <Navbar expand="lg" className="ml-4">
            <Navbar.Brand as={NavLink} to="/">
            <div className="d-flex align-items-center">
                <Image
                    src={Logo}
                    alt="Logo"
                    style={{
                        width: '60px',
                        height: 'auto',
                        filter: 'invert(42%) sepia(27%) saturate(1079%) hue-rotate(223deg) brightness(89%) contrast(88%)' // Makes it #7a57d1 purple
                    }}
                />
                <span className="ms-2 header-logo">{Consts.ProjectName}</span>
            </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
                <Nav.Link as={NavLink} to={Paths.Home.path} className={`header-link ${location.pathname === Paths.Home.path ? 'text-decoration-underline' : ''}`}>{t('Home')}</Nav.Link>
                <Nav.Link as={NavLink} to={Paths.About.path} className={`header-link ${location.pathname === Paths.About.path ? 'text-decoration-underline' : ''}`}>{t('About')}</Nav.Link>
                <Nav.Link as={NavLink} to={Paths.Pricing.path} className={`header-link ${location.pathname === Paths.Pricing.path ? 'text-decoration-underline' : ''}`}>{t('Pricing')}</Nav.Link>
                {profile.name ? (
                    <>
                    <Nav.Link as={NavLink} to={Paths.Profile.path} className={`header-link ${location.pathname === Paths.Profile.path ? 'text-decoration-underline' : ''}`}>{t('Profile')}</Nav.Link>
                    <Button variant="light" onClick={handleSignOut} className="custom-button">{t('Sign Out')}</Button>
                    </>
                    ) : (
                        <>
                        <Button variant="light" as={NavLink} to={Paths.Signin.path} className={`custom-button`}>{t('Sign In')}</Button>
                        <Button variant="light" as={NavLink} to={Paths.Signup.path} className={`custom-button`}>{t('Start for Free')}</Button>
                        </>    
                    )
                }

            </Nav>
            </Navbar.Collapse>
        </Navbar>
        </header>
    </>
  )
}

export default Header;
