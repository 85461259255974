import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Consts } from "consts";
import { useTranslation } from "react-i18next";
import Page from "components/page";
const AboutPage = () => {
  const [t] = useTranslation('common');

  return (
    <Page title={t('About')} description={t('About')}>
        <Container className="min-vh-100 my-5">
            <Row className="justify-content-md-center">
                <Col>
                <h1 className="mb-4">{t('About')} {Consts.ProjectName}</h1>
                <p>
                {t('Welcome to')} <strong>{Consts.ProjectName}</strong> {t('your ultimate solution for AI-powered grammar checking of your website\'s pages.')}
                </p>

                <h3 className="mt-5 mb-3">{t('What We Offer')}</h3>
                <ul>
                    <li>
                    <strong>{t('Advanced Grammar Checking')}:</strong> {t('Our modern generative AI scans your website to detect and correct grammar errors.')}
                    </li>
                    <li>
                    <strong>{t('Detailed Reports')}:</strong> {t('Receive comprehensive insights to enhance your content\'s clarity and professionalism.')}
                    </li>
                </ul>

                <h3 className="mt-5 mb-3">{t('Our Mission')}</h3>
                <p>
                {t('At')} <strong>{Consts.ProjectName}</strong> {t(', our mission is to help website owners improve the quality of their online content by providing cutting-edge grammar checking tools powered by AI.')}
                </p>

                <h3 className="mt-5 mb-3">{t('Want to Help or Partner with Us?')}</h3>
                <p>
                {t('If you’d like to help us improve the service or explore partnership opportunities, feel free to reach out to')} {' '}
                <a href={`mailto:${Consts.ContactEmail}`}>{Consts.ContactEmail}</a>.
                </p>
                </Col>
            </Row>
        </Container>
    </Page>
  );
};

export default AboutPage;