import { React, useState, useEffect } from 'react';
import getBackend from 'backend/backend';
import { useTranslation } from "react-i18next";
import { unixTimestampToString } from 'components/misc';
import { Table, Button, Container } from 'react-bootstrap'; // Importing necessary components
import { Paths } from 'paths';
import SidebarPage from 'components/sidebar_page';

const Reports = () => {
    const [reports, setReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [t] = useTranslation('common');

    const itemsPerPage = 10;

    useEffect(() => {
        let canceled = false;

        const fetchReports = async () => {
            const offset = (currentPage - 1) * itemsPerPage;
            const result = await getBackend().getReports(offset, itemsPerPage);
            if (canceled) return;

            if (result.error == null) {
                setReports(result.response.reports);
            }
        };

        fetchReports();

        return () => {
            canceled = true;
        };
    }, [currentPage]);

    const paginatedReports = reports.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <SidebarPage title={t('Reports')} description={t('Reports')}>
            <Container className="my-5">
                <h2 className="mb-4">{t('Reports')}</h2>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('ID')}</th>
                            <th>{t('Url')}</th>
                            <th>{t('Status')}</th>
                            <th>{t('Progress')}</th>
                            <th>{t('Created')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedReports.map(report => (
                            <tr key={report.id}>
                                <td><a className="report-link" href={Paths.Report.path + "/" + report.id}>{report.id}</a></td>
                                <td><a className="page-link" href={report.url}>{report.url}</a></td>
                                <td>{report.report_error !== "" ? "error" : report.status}</td>
                                <td>{report.progress}</td>
                                <td>{unixTimestampToString(report.created_ts/1000000)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {reports.length > itemsPerPage &&
                    <div className="mt-3">
                        <Button 
                            variant="light" 
                            className="custom-button"
                            disabled={currentPage === 1} 
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            {t('Previous')}
                        </Button>
                        <Button 
                            variant="light" 
                            className="custom-button"
                            disabled={currentPage * itemsPerPage >= reports.length} 
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                        {t('Next')}
                        </Button>
                    </div>
                }
            </Container>
        </SidebarPage>
    );
};

export default Reports;
