import { React, useState, useEffect } from 'react';
import getBackend from 'backend/backend';
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Alert } from 'react-bootstrap';
import SidebarPage from 'components/sidebar_page';
import { ai_models, langs } from './new_report';

const Settings = () => {
    const [t] = useTranslation('common');
    const [settings, setSettings] = useState({ ai_model: '',
            lang: '', url: '', scheduled_check_period: 0, urls_limit: 0, email_notification: false});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let canceled = false;

        const fetchSettings = async () => {
            try {
                const result = await getBackend().getSettings();
                if (!canceled && result.error == null) {
                    setSettings(result.response.settings);
                } else if (!canceled) {
                    setError(result.error);
                }
            } catch (err) {
                if (!canceled) setError(err.message);
            } finally {
                if (!canceled) setLoading(false);
            }
        };

        fetchSettings();

        return () => {
            canceled = true;
        };
    }, []);

    const handleScheduledCheckPeriodChange = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            scheduled_check_period: parseInt(e.target.value)
        }));
    };

    const handleUrl = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            url: e.target.value
        }));
    };

    const handleAiModel = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            ai_model: e.target.value
        }));
    };

    const handleLang = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            lang: e.target.value
        }));
    };

    const handlePageLimit = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            urls_limit: Number(e.target.value)
        }));
    };

    const handleEmailNotification = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            email_notification: e.target.checked
        }));
    };

    const handleSave = async () => {
        try {
            const result = await getBackend().setSettings({"settings": settings});
            if (result.error != null) {
                setError(result.error);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <SidebarPage title={t('Settings')} description={t('Settings')}>
            <Container className="my-5">

                <h2 className="mb-4">{t('Settings')}</h2>
                {loading &&
                    <div>{t('Loading...')}</div>
                }
                {error &&
                    <Alert variant="danger">{t('Error')}: {error}</Alert>
                }
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Site URL</Form.Label>
                        <Form.Control
                                type="text"
                                name="Site URL"
                                value={settings.url}
                                onChange={handleUrl}
                        />
                        <Form.Label>Language</Form.Label>
                        <Form.Select value={settings.lang} onChange={handleLang}>
                                    {langs.map((lang, index) => (
                                        <option key={index} value={lang}>{lang}</option>
                                    ))}
                        </Form.Select>
                        <Form.Label>Page limit</Form.Label>
                        <Form.Control
                                    type="number"
                                    placeholder="Enter pages limit"
                                    value={settings.urls_limit}
                                    onChange={handlePageLimit}
                        />
                        <Form.Label>AI model</Form.Label>
                        <Form.Select value={settings.ai_model} onChange={handleAiModel}>
                                    {ai_models.map((model, index) => (
                                        <option key={index} value={model}>{model}</option>
                                    ))}
                        </Form.Select>
                        <Form.Label>Scheduled site inspection interval {settings.scheduled_check_period} days</Form.Label>
                        <Form.Range
                                className="settings-range"
                                value={settings.scheduled_check_period}
                                onChange={handleScheduledCheckPeriodChange}
                                min={0}
                                max={30}
                        />
                        <Form.Label>Email notifications</Form.Label>
                        <Form.Check
                            className="settings-switch"
                            type="switch"
                            checked={settings.email_notification}
                            onChange={handleEmailNotification}
                        />
                    </Form.Group>
                    <Button variant="light" className="custom-button" onClick={handleSave}>{t('Save')}</Button>
                </Form>
            </Container>
        </SidebarPage>
    );
};

export default Settings;
